import { userAxios } from '../../index'

const reviewSystemCategoryList = async (perPage) => {
    try {
        return await userAxios.get(`rwOfSystem/categories/all/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryCreate = async (data) => {
    try {
        return await userAxios.post('rwOfSystem/categories', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryUpdate = async (id, data) => {
    try {
        return await userAxios.put(`rwOfSystem/categories/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
} 

const reviewSystemCategoryDelete = async (id) => {
    try {
        return await userAxios.delete(`rwOfSystem/categories/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`rwOfSystem/categories/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`rwOfSystem/categories/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`rwOfSystem/categories/search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    reviewSystemCategoryList,
    reviewSystemCategoryCreate,
    reviewSystemCategoryUpdate,
    reviewSystemCategoryDelete,
    reviewSystemCategoryPagination,
    reviewSystemCategoryFilter,
    reviewSystemCategoryFilterPagination
}